
.top-main{
  position: relative;

  @include mq-pc(){
    width: 100%;
    height: 540px;
  }
  @include mq-sp(){
    width: 100%;
    height: 40vh
  }

  .fade{
    width: 70%;
    margin-left: 30%;

    @include mq-sp(){
      width: 80%;
      margin-left: 20%;
    }
  }

  &:after{
    content: "";
    position: absolute;
    left: 0;
    top:0;
    background: url("../images/top/top_main_prt_01.png") no-repeat top right;
    background-size: cover;

    @include mq-pc(){
      height: 540px;
      width: 50%;
    }
    @include mq-sp(){
      width: 50%;
      height: 40vh;
    }
  }

  h1{
    position: absolute;
    z-index: 2;

    @include mq-pc(){
      width: 430px;
      top:80px;
      left: 50%;
      margin-left: -450px;
    }
    @include mq-sp(){
      width: 220px;
      top:30px;
      left: 20px;
    }
  }

  figure{
    width: 100%;

    background: url("../images/top/main_01.jpg") no-repeat center center;
    background-size: cover;

    @include mq-pc(){
      height: 540px;
    }
    @include mq-sp(){
      height: 40vh;
    }
    &#p2{
      background: url("../images/top/main_02.jpg") no-repeat center center;
      background-size: cover;
    }
    &#p3{
      background: url("../images/top/main_03.jpg") no-repeat center center;
      background-size: cover;
    }
  }
}
#top-news{
  position: relative;
  background: url("../images/top/top_news_back_01.png") no-repeat bottom center;
  background-size:100% auto;
  padding: 120px 0 50% 0;

  @include mq-sp(){
    padding: 10% 0 60% 0;
  }

  &:after,
  &:before{
    content: "";
    position: absolute;
  }
  &:after{
    background: url("../images/common/pct_korn.svg") no-repeat;
    background-size: cover;

    @include mq-pc(){
      width: 202px;
      height: 182px;
      top:157px;
      left: 50%;
      margin-left: -500px;
    }
    @include mq-sp(){
      width: 81px;
      height: 73px;
      top:80px;
      left: 10px;
    }
  }
  &:before{
    background: url("../images/common/pct_chinesecabbage.svg") no-repeat;
    background-size: cover;

    @include mq-pc(){
      top:253px;
      left: 50%;
      margin-left: 300px;
      width: 118px;
      height: 159px;
    }
    @include mq-sp(){
      width: 59px;
      height: 80px;
      top:120px;
      right: 10px;
    }
  }
  p{

    &.prt{
      text-align: center;
      img{
        width: 45px;
      }
    }
    &.text{
      margin-top: 55px;
      text-align: center;
      @include mq-sp(){
        margin-top: 12%;
      }
    }
    &.btn{
      text-align: center;
      font-size: 0.9rem;
      margin-top: 65px;
      font-weight: bold;
      line-height: 1.0;

      @include mq-sp(){
        margin-top: 12%;
      }
      a{
        @include original-button();
        @include mq-sp(){
          width: 80%;
          margin: 0 auto;
          padding: 15px 0;
        }
      }
    }
  }

  h1{
    margin-top: 18px;
    text-align: center;
    line-height: 1.2;
    img{
      width: 152px;
    }
    span{
      @include open-sans();
      font-size: 0.74rem;
      font-weight: bold;
    }
  }

}
.news-list{
  @include mq-pc(){
    width: 880px;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
  }
  @include mq-sp(){
    width: 88%;
    margin: 12% auto 0;
  }

  .b{
    position: relative;

    @include mq-pc(){
      width: 250px;
      margin-top: 55px;
      margin-right: 65px;

      &:nth-child(3n){
        margin-right: 0;
      }
    }
    @include mq-sp(){
      width: 100%;
      margin-top: 12%;
    }
  }
  a{
    text-decoration: none;
    &:hover{
      figure{
        transform: scale(1.1,1.1) rotate(10deg);

      }
    }
    @include mq-sp(){
      display: flex;
      justify-content: space-between;
    }
  }
  figure{
    background-size: cover;
    position: relative;
    transition: 0.5s;
    @include mq-pc(){
      width: 100%;
      height: 155px;
    }
    @include mq-sp(){
      width: 40%;
      &:after{
        content: "";
        display: block;
        padding-top: 20%;
      }
    }
  }
  .text-filed{
    padding-top: 25px;
    @include mq-sp(){
      padding-top: 2%;
      width:55%;
    }

  }
  h2{
    font-size: .8rem;
    font-weight: bold;
    color: #5E4E2E;
    line-height: 1.8;
  }
  p{
    &.new{
      img{
        position: absolute;
        width: 66px;
        height: 66px;
        left: -30px;
        top:-30px;
        z-index: 3;
      }
      @include mq-sp(){
        img{
          width: 40px;
          height: 40px;
          left: -20px;
          top:-20px;
        }
      }
    }
    &.cate{
      color: #fff;
      background: #1799CC;
      padding: 8px 12px;
      line-height: 1.0;
      font-size: 0.68rem;
      @include mq-sp(){
        padding: 4px 12px;
      }
    }
    &.c2{
      background: #E2478E
    }
    &.date{
      font-size: 0.68rem;
      font-weight: bold;
    }
  }
  .cate-date{
    margin-top: 18px;
    display: flex;
    justify-content: space-between;
  }
}
#top-fun{
  padding: 100px 0 100px 0;
  background: url("../images/common/back_02.svg");
  background-size: 23px 23px;


  @include mq-sp(){
    padding: 15% 0;
  }
  .title-field{
    width: 544px;
    margin: 0 auto;
    position: relative;

    @include mq-sp(){
      width: 85%;
    }

    h1{
      img{
        width: 100%;
      }
    }
    p{
      &.prt{
        right: -60px;
        top:0px;
        position: absolute;
        img{
          width: 82px;
          height: 68px;
        }

        @include mq-sp(){
          right: -30px;
          top:-0px;
          img{
            width: 55px;
            height: 45px;
          }
        }
      }
    }
  }

  .box{
    @include mq-pc(){
      width: 815px;
      margin: 55px auto 0;
      display: flex;
      justify-content: space-between;
    }
    @include mq-sp(){
      width: 92%;
      margin: 20% auto 0;
    }

    .img-field{
      position: relative;
      width: 310px;
      height: 310px;

      @include mq-sp(){
        width: 260px;
        height: 260px;
        margin: 12% auto 0;
      }

      figure{
        position: relative;
        z-index: 2;
        img{
          width: 100%;
        }
      }

      &:after{
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 1;
        border-radius: 50%;
        background: url("../images/common/back_03.svg");
        background-size: 32px 32px;
        top:10px;
        left: 10px;
      }
      p{
        &.prt{
          display: inline-block;
          position: absolute;
          width: 156px;
        }
      }
    }
    .text-field{
      padding-top: 35px;
      width: 390px;

      @include mq-sp(){
        padding-top: 13%;
        width: 85%;
        margin: 3% 0 0 12%;
      }
      h2{
        width: 162px;
        position: relative;
        &:after{
          content: "";
          position:absolute;
          width: 200px;
          height: 1px;
          background: #333;
          z-index: 2;

          @include mq-sp(){
            width: 1px;
            height: 120px;
            top: -130px;
            left: 30px;
          }
        }
      }
      p{
        &.text{
          margin-top: 25px;
        }
        &.btn{
          margin-top: 35px;
          text-align: right;

          @include mq-sp(){
            margin-top: 5%;
          }
          a{
            @include original-button();
            padding: 8px 35px;
            font-weight: bold;
          }
        }
      }
    }
  }
  .box-shopping,
  .box-cafe{
    .img-field{
      p{
        &.prt{
          position: absolute;
          top:-40px;
          left: -40px;
        }
      }
    }
    .text-field{
      h2{
        @include mq-pc(){
          &:after{
            content: "";
            top:20px;
            left: -220px;
          }
        }
      }
    }
  }
  .box-eat{
    flex-flow:row-reverse;
    .img-field{
      p{
        &.prt{
          position: absolute;
          top:-40px;
          right:-80px;
        }
      }
    }
    .text-field{
      h2{
        @include mq-pc(){
          &:after{
            content: "";
            width: 400px;
            top:20px;
            right: -450px;
          }
        }
      }
    }
  }
}
#top-sanpo{
  padding: 85px 0;
  width: 1000px;
  margin: 0 auto;

  @include mq-sp(){
    width: 92%;
    padding: 12% 0;
  }

  .inner{
    background: url("../images/top/top_sampo_back_01.svg") no-repeat;
    background-size: 295px 220px;

    @include mq-pc(){
      width: 980px;
      display: flex;
      justify-content: space-between;
      background-position: 320px 300px;
    }

    @include mq-sp(){
      width: 90%;
      margin: 8% auto;
      background-position: 0px 200px;
    }
  }

  .text-field{
    padding-top: 75px;
    width: 430px;

    @include mq-sp(){
      padding-top: 10%;
      width: 92%;
      margin: 0 auto;
    }
    h1{
      position: relative;

      img{
        width: auto;
        height: 150px;
      }
      @include mq-sp(){
        img{
          height: 38vw;
        }
      }

      &:after{
        content: "";
        position:absolute;

        background: #333;
        z-index: 2;

        @include mq-pc(){
          width: 300px;
          height: 1px;
          top:80px;
          right: -230px;
        }
        @include mq-sp(){
          width: 1px;
          height: 200px;
          top:180px;
          left: 0px;
        }
      }
    }
    p{
      margin: 50px 0px 0px 60px;

      @include mq-sp(){
        margin: 10% 0px 0px 8%;
      }
    }

  }
  .img-field{
    width: 435px;
    position: relative;

    @include mq-sp(){
      width: 92%;
      margin: 8% auto 0;
    }
    p{
      &.prt{
        width: 100px;
        position: absolute;
        bottom:0;
        left: -30px;
      }
    }
  }
}

.img-slider{
  width: 100%;
  figure{
    width: 346px;
  }
}
