#news-title{
  figure{
    background: url("../images/news/news_main.jpg") no-repeat center center;
    background-size: cover;
  }
  .title-field{

    position: absolute;
    z-index: 3;

    @include mq-pc(){
      width: 365px;
      top:50px;
      left: 50%;
      margin-left: -450px;
    }
    @include mq-sp(){
      width: 200px;
      top:30px;
      left: 20px;
    }
  }
}

.news-contets{
  padding: 125px 0;
  @include mq-sp(){
    padding: 15% 0;
  }
  p{
    &.text{
      text-align: center;
    }
  }
  .page{
    margin-top: 75px;
    ul{
      display: flex;
      justify-content: center;
      align-items: center;
      li{
        a{
          text-decoration: none;
          font-size: 1rem;
          @include open-sans();
          border: 1px solid #1E1E1E;
          display: block;
          padding: 3px 8px;
          margin-right: 8px;
          font-weight: bold;
        }
      }
    }
  }
}

.post-nav{
  background: url("../images/common/special_back_01_02.png") top center;

  .inner{
    background: url("../images/common/special_back_01.png") no-repeat top center;
    padding: 80px 0;
    @include mq-sp(){
      padding: 15% 0;
    }
  }
  .in{
    margin: 0 auto;

    @include mq-pc(){
      display: flex;
      justify-content: space-between;
      width: 600px;
    }
    @include mq-sp(){
      width: 90%;
    }
    .b{
      width: 45%;
      @include mq-sp(){
        width: 100%;
        &:nth-child(2){
          h2{
            margin-top: 10%;
          }
        }
      }
    }

    h2{
      padding-bottom: 18px;
      font-size: 1rem;
      font-weight: bold;
      line-height: 1.0;
      &:before{
        content: "●";
      }
      @include mq-sp(){
        padding-bottom: 3%;
      }
    }
    ul{
      margin-left: 30px;

      @include mq-sp(){
        margin-left: 8%;
      }

      li{
        line-height: 1.0;
        margin-top: 15px;

        @include mq-sp(){
          margin-top: 5%;
        }
        a{
          font-size: 0.9rem;
          font-weight: bold;
          text-decoration: none;
          border:1px solid #D8D1C5;
          background: #fff;
          display: block;
          padding: 12px 18px;
          position: relative;
          border-radius: 3px;
          i{
            @include draw-allow($c:#333);
            right: 20px;
            transform: scale(-1);
          }
        }
      }
    }
    .selecter{
      margin-left: 30px;
      width: 100%;
      margin-top: 12px;
      width: 90%;

      @include custom-selecter();
      select{
        font-size: 0.9rem;
      }
    }
  }
}

.news-post{
  position: relative;

  &:after{
    content: "";
    position: absolute;
    width: 100%;
    height: 400px;
    top:300px;
    background: url("../images/common/back_brown.jpg");
    transform:skewY(-10deg);
    z-index: -1;
  }

  .inner{
    width: 1000px;
    margin: 0 auto;
    padding: 85px 0;
    @include mq-sp(){
      width: 90%;
      padding: 12% 0;
    }
  }

  .post-title{
    @include mq-pc(){
      display: flex;
      justify-content: space-between;
    }
  }
  .img-field{
    width: 440px;
    line-height: 0;
    padding: 8px;
    background: #fff;
    transform: rotate(6deg);
    box-shadow: 0 0 3px #444;

    @include mq-sp(){
      width: 90%;
      margin: 15% auto 0;
    }

    figure{
      width: 100%;
      background-size: cover;
      background-position: center center;
      &:after{
        content: "";
        display: block;
        padding: 35%;
      }
    }
  }
  .text-field{
    width: 500px;
    padding-top: 35px;

    @include mq-sp(){
      width: 100%;
      padding-top: 10%;
    }
    .cate-date{
      margin-top: 18px;
      display: flex;
        li{
          &.cate{
            color: #fff;
            background: #1799CC;
            padding: 8px 12px;
            line-height: 1.0;
            font-size: 0.68rem;
            margin-right: 20px;
            @include mq-sp(){
              padding: 4px 12px;
            }
          }
          &.c2{
            background: #E2478E
          }
          &.date{
            font-size: 0.68rem;
            font-weight: bold;
          }
        }
    }

    h2{
      font-weight: bold;
      font-size: 1.6rem;
      margin-top: 20px;
      color: #5E4E2E;

      @include mq-sp(){
        font-size: 1.5rem;
        margin-top: 5%;
      }
    }
  }
  .post-box{
    @include mq-pc(){
      width: 850px;
      margin: 55px auto ;
    }
    @include mq-sp(){
      margin-top: 12%;
    }
    p{
      font-size: 0.9rem;
    }
  }
  .post-text{

  }
  .post-img-left,
  .post-img-right{
    @include mq-pc(){
      display: flex;
      justify-content: space-between;
    }

    figure{
      width: 400px;
      img{
        width: 100%;
      }
      @include mq-sp(){
        width: 100%;
      }
    }
    p{
      width: 400px;
      @include mq-sp(){
        width: 100%;
        margin-top: 8%;
      }
    }
  }
  .post-img-right{
    @include mq-pc(){
      display: flex;
      flex-direction:row-reverse;
    }
  }
  .img-only{
    @include mq-pc(){
    figure{
      text-align: center;
      img{
        width: 80%;
      }
    }
  }
  }

  .page-nation{
    padding-bottom:100px;
    ul{
      display: flex;
      justify-content: center;
      align-items: center;
      li{
        a{
          position: relative;
          font-size: 0.8rem;
        }
        &.index{
          width: 80px;
          a{
            height: 30px;
            display: block;
            span{
              top:15px;
              left: 38px;
              position:absolute;
              @include drow-three-line($width:22px);
            }
          }
        }
        &.prev,
        &.next{
          a{
            i{
              top:0px;
                @include draw-allow();
            }
          }
        }
        &.prev{
          a{
            padding-left: 20px;
            i{
              left: 0;
            }
          }
        }
        &.next{
          a{
            padding-right: 20px;
            i{
              right: 0;
              transform: scale(-1);
            }
          }
        }
      }
    }
  }
}
