


body{
  background: url("../images/common/back_01.png");
  padding-top: 100px;
  @include mq-sp(){
    padding-top: 65px;
  }
}

.wrapper{
  @include mq-sp{
    overflow: hidden;
  }
}


p{
  color: #5E4E2E;
}

header{
  position: fixed;
  background: #fff;
  z-index: 998;
  width: 100%;
  top:0;
  border-bottom:3px solid #333;

  .inner{
    display: flex;
    justify-content: space-between;
  }

  @include mq-pc(){
    .inner{
      padding: 18px 0 18px 0;
      width: 1000px;
      margin: 0 auto;
    }
    .sp-nav{
      display: none;
    }
  }
  @include mq-sp(){
    .inner{
      height: 65px;
    }
    .pc-nav{
      display: none;
    }
    border-bottom:2px solid #333;
  }

  p{
    &.logo{
      width: 220px;
      line-height: 1;
    }
    @include mq-sp(){
      &.logo{
        position: relative;
        top:10px;
        left: 12px;
        width: 140px;
      }
    }
  }
  h1{
    font-size: 0.68rem;
    text-align: right;
  }
  nav{
    @include mq-pc(){
      padding-top: 8px;
      ul{
        padding-top: 8px;
        display: flex;
        li{
          line-height: 1.0;
          font-weight: bold;
          padding: 0px 15px 0px 0px;

          a{
            position: relative;
            display: inline-block;
            box-sizing: border-box;
            font-size: 0.82rem;
            font-weight: bold;
            text-decoration: none;
            padding: 12px 8px 12px 30px;
            position: relative;

            &.gnav{
              &:before{
                content: "";
                position: absolute;
                width: 100%;
                height: 6px;
                background: #FFCC00;
                left: 0;
                bottom:9px;
                transition: 0.35s;
                transform: scaleX(0);
                z-index: -1;
              }
              &:hover{
                &:before{
                  transform: scaleX(1);
                }
              }
            }


            &.contact{
              @include original-button();
              border: 1px solid #333;
              padding: 12px 25px;
            }
            img{
              top:5px;
              left: 0;
              position: absolute;
              width: 26px;
              height: 26px;
            }
          }
        }
      }
    }
    @include mq-sp(){
      display: flex;
      ul{
        display: flex;
        padding-right: 65px;
        li{
          margin-left: 8px;
          a{
            padding-top: 18px;
            text-align: center;
            display: inline-block;
            width: 40px;
            height: 65px;
          }
          &.access{
            img{
              height: 40px;
              width: 40px;
            }
          }
          &.tel{
            img{
              position: relative;
              top:4px;
              height: 25px;
              width: 25px;
            }
          }
        }
      }
    }
  }
}

.footer-info{
  @include mq-pc(){
    display: flex;
    justify-content: center;
  }

  h1{
    text-align: center;
    img{
      width: 227px;
      height: 73px;
    }
  }
  p{
    text-align: center;
    &.tel{
      position: relative;
      line-height: 1.2;
      padding-top: 8px;
      margin: 0px 35px;
      span{
        font-size: 2.3rem;
        color: #000;
        @include open-sans();
        font-weight: bold;
        img{

          width: 30px;
          height: 30px;
          margin-right: 8px;
        }
      }

      &:after{
        top:16px;
        left: 0;
        position: absolute;
        content: "";

        background: url("../images/common/pct_tel.svg") no-repeat;
        background-size: cover;
      }
    }
  }
  ul{
    @include mq-sp(){
      margin-top: 3%;
    }
    li{

      line-height: 1.0;
      font-size: 1rem;
      font-weight: bold;
      vertical-align: middle;
      align-items: center;
      display: flex;
      padding-top: 8px;

      @include mq-sp(){
        justify-content: center;
      }
      img{
        width: 23px;
        height: 23px;
        margin-right: 8px;
      }
      @include mq-sp(){
        margin-left: 8px;
      }
    }
  }
}

footer{
  padding: 85px 0 0;
  position: relative;

  @include mq-sp(){
    padding: 12% 0 0;
  }
  &:after,
  &:before{
    content: "";
    position: absolute;
  }
  &:after{
    background: url("../images/common/pct_pumpkin.svg") no-repeat;
    background-size: cover;
    @include mq-pc(){
      width: 140px;
      height: 123px;
      top:80px;
      left: 50%;
      margin-left: -600px;
    }

    @include mq-sp(){
      width: 70px;
      height: 61px;
      top:10%;
      right: -20px;
    }
  }
  &:before{
    background: url("../images/common/pct_turnip.svg") no-repeat;
    background-size: cover;
    @include mq-pc(){
      width: 149px;
      height: 238px;
      top:10px;
      left: 50%;
      margin-left: 500px;
    }
    @include mq-sp(){
      width: 75px;
      height: 120px;
      top:-0%;
      left: -20px;
    }
  }


  .shop-list{
    margin-top: 55px;
    .inner{
      @include mq-pc(){
        display: flex;
        justify-content: center;
      }
    }
    .b{
      width: 250px;
      box-sizing:content-box;
      @include mq-sp(){

        width: 90%;
        margin: 8% auto 0;
        padding-top: 8%;
        border-top:1px solid #DCDCDC;
      }
      h2{
        img{
          width: 158px;
          height: 50px;
        }
        @include mq-sp(){
          text-align: center;
        }
      }
      @include mq-pc(){
        &:nth-child(2){
          padding: 0px 45px;
          margin: 0px 45px;
          border-left:1px solid #DCDCDC;
          border-right:1px solid #DCDCDC;
          h2{
            img{
              width: 119px;
              height: 50px;
            }
          }
        }
      }
      &:nth-child(3){
        h2{
          img{
            width: 189px;
            height: 50px;
          }
        }
      }
    }

    p{
      margin-top: 8px;
      letter-spacing: 1px;
      font-size: .68rem;
      line-height: 1.0;
      font-weight: bold;
      @include mq-sp(){
        text-align: center;
      }

      &.caution{
        text-align: center;
        margin-top: 55px;
      }
    }

    dl{
      margin-top: 18px;
      display: flex;
      flex-wrap: wrap;
      font-size: 0.78rem;

      align-items:flex-start;

      @include mq-pc(){
        justify-content: space-between;
      }
      @include mq-sp(){
        justify-content: center;
      }
      dt,dd{
        margin-top: 8px;
        white-space: nowrap;
        @include mq-sp(){
          margin-right: 8px;
        }
      }
      dt{
        font-weight: normal;
        line-height: 1.0;
        text-align: center;

        color: #fff;
        font-size: 0.68rem;
        padding: 5px 12px;
        background: #159A51;

        @include mq-pc(){
          width: 22%;
        }
        @include mq-sp(){
          padding: 5px 3px;
        }
        &.touki{
          background: #205C8D;
        }
      }
      dd{
        line-height: 1.6;



        @include mq-pc(){
          width: 70%;
          font-size: 0.78rem;
          span{
            font-size: 0.68rem;
          }
        }
        @include mq-sp(){
          font-size: 0.68rem;
          span{
            font-size: 0.62rem;
          }
        }

      }
    }
  }

  .footer-access{
    margin-top: 105px;
    padding-top: 85px;
    background: url("../images/common/footer_line_01.svg") repeat-x;
    background-position: top center;
    background-size: 1500px 11px;
    position: relative;
    @include mq-sp(){
      margin-top: 25%;
      padding-top: 10%;
    }
    &:after{
      content: "";
      position: absolute;
      width: 55px;
      height: 28px;
      background: url("../images/common/pct_car.svg") no-repeat;
      top: -22px;
      left: 50%;
      margin-left: -27px;
    }

    h2{
      text-align: center;
      line-height: 1.2;
      img{
        width: 125px;
        height: 26px;
      }
      span{
        font-size: 0.78rem;
        @include open-sans();
        font-style: italic;
        letter-spacing: 2px;
        font-weight: bold;
      }
    }
    p{
      &.address{
        text-align: center;
        margin-top: 38px;
      }
    }

    .gmap{
      width: 850px;
      border: 3px solid #333;
      padding: 10px;
      margin: 55px auto 0;
      position: relative;

      iframe{
        width: 100%;
        height: 300px;
      }

      @include mq-sp(){
        width: 90%;
        margin: 10% auto 0;
        iframe{
          width: 100%;
          height: 200px;
        }
      }

      p{
        &.btn{
          position: absolute;
          line-height: 1.0;
          right: 10px;
          bottom:-15px;

          a{
            @include original-button();
            position: relative;
            padding: 12px 35px 12px 18px;
            background: #fff;
            i{
              transform: scale(-1);
              right: 15px;
              top:12px;
              @include draw-allow($c:#333);
              z-index: 2;
            }
          }
        }
      }
    }
    .trafic{
      display: flex;
      justify-content: space-between;

      @include mq-pc(){
        width: 650px;
        margin: 35px auto 0;
      }
      @include mq-sp(){
        width: 90%;
        margin: 10% auto 0;
      }

      .b{
        @include mq-pc(){
          width: 310px;
          display: flex;
        }
        @include mq-sp(){
          width: 48%;
          display: flex;
        }
      }
      figure{
        margin-right: 20px;
        img{
          width: 49px;
          height: 49px;
        }
        @include mq-sp(){
          img{
            width: 40px;
            height: 40px;
          }
        }
      }
      h3{
        display: inline-block;
        position: relative;
        font-size: 0.8rem;
        &:after{
          content: "";
          position: absolute;
          height: 1px;
          width: 100%;
          background: #333;
          bottom:-3px;
          left: 0;
        }
      }
      p{
        color: #333;
        margin-top: 12px;
        line-height: 1.6;
      }
    }
  }
  .f-last{
    background: #F3F3F3;
    padding: 55px 0;
    margin-top: 55px;
    position: relative;
    @include mq-sp(){
      padding: 10% 0;
      margin-top: 10%;
    }

    &:after{
      position: absolute;
      content: "";
      background: url("../images/common/pct_persimmon.svg") no-repeat;
      background-size: cover;

      @include mq-pc(){
        width: 133px;
        height: 106px;
        top:-50px;
        left: 50%;
        margin-left: -600px;
      }
      @include mq-sp(){
        width: 68px;
        height: 53px;
        top:-30px;
        left: 0;

      }
    }

    .inner{
      margin: 0 auto;
      @include mq-pc(){
        width: 700px;
        display: flex;
        justify-content: space-between;
      }
      @include mq-sp(){
        width: 90%;
      }
    }
    .co-l{
      padding-top: 12px;
      h3{
        font-size: 0.72rem;
        color: #333;
        font-weight: bold;
        @include mq-sp(){
          text-align: center;
        }
      }
      figure{
        margin-top: 12px;
        text-align: left;
        img{
          width: auto;
          height: 70px;
        }
        @include mq-sp(){
          text-align: center;
        }
      }
      p{
        
        font-size: 0.72rem;
        color: #333;
        @include mq-sp(){
          text-align: center;
        }
      }
    }
    .co-r{
      padding-top: 12px;

      ul{
        display: flex;
        justify-content:flex-start;

        @include mq-sp(){
          justify-content:center;
        }
        li{
          width: 170px;
          margin-right: 18px;
          &:nth-child(2){
            margin-right: 0;
          }
          img{
            width: 100%;
          }
        }
      }

      p{
        &.link{
          margin-top: 8px;

          @include mq-sp(){
            text-align: center;
          }
        }
      }
    }
    p{
      &.copyright{
        margin-top: 18px;
        font-size: 0.68rem;
        text-align: center;
      }
    }
  }
}
.ulc-title{

  $titleHeight: 294px;
  $titleHeightSP: 25vh;

  position: relative;

  @include mq-pc(){
    width: 100%;
    height: $titleHeight;
  }
  @include mq-sp(){
    width: 100%;
    height: $titleHeightSP;
  }
  figure{
    width: 60%;
    margin-left: 40%;

    @include mq-pc(){
      height: $titleHeight;
    }
    @include mq-sp(){
      height: $titleHeightSP;
      width: 80%;
      margin-left: 20%;
    }
  }

  &:after{
    content: "";
    position: absolute;
    left: 0;
    top:0;
    background: url("../images/common/ulc_title_bak.png") no-repeat top right;
    background-size: cover;

    @include mq-pc(){
      height: $titleHeight;
      width: 50%;
    }
    @include mq-sp(){
      width: 50%;
      height: $titleHeightSP;
    }
  }

  h1{
    width: 100%;
  }
  p{
    color: #F9E710;
    font-size: 1.3rem;
    text-align: right;
    font-weight: bold;
    @include open-sans();
    font-style: italic;
  }
}
