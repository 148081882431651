

.navi-button{
  @include mq-pc(){
    display: none;
  }
  position:absolute;
  top:0;
  right: 0;
  width: 50px;
  height: 65px;

  z-index: 999;
  .inner{
    width: 50%;
    margin: 0 auto;
    position: relative;
    top:35px;

    @include drow-three-line();
  }
}
.navi-button-on{
  .inner{
    i{
      opacity: 0;
      transform: translateX(10px);
    }
    &:after{
      top:0;
      transform: rotate(-45deg);
    }
    &:before{
      top:0;
      transform: rotate(45deg);
    }
  }
}

.sp-menu{

  z-index: 7;
  position: fixed;
  width: 100%;
  top:65px;
  overflow-x: auto;
  height: calc(100vh - 65px);
  transition: 0.3s;
  transform-style: preserve-3d;

  transform:scaleX(0);
  background: #FDD002;

  nav{
    padding: 35px;

    ul{
      li{
        text-align: center;
        font-size: 1rem;
        font-weight: bold;
        line-height: 1.2;
        width: 80%;
        margin: 35px auto 0;

        &:first-child{
          margin-top: 0;
        }
        a{
          text-decoration: none;
          display: block;
          display: flex;
          align-items:center;
          position: relative;
          color: #333;
          img{
            width: 50px;
            height: 50px;
          }
          i{
            position: absolute;
            right: 20px;
            top:18px;
            &:after,
            &:before{
              position: absolute;
              content: "";
              width: 12px;
              height: 2px;
              background: #333;
            }
            &:after{
              top:-4px;
              transform: rotate(45deg);

            }
            &:before{
              top:4px;
              transform: rotate(-45deg);
            }
          }
        }
      }
    }

  }
}

.contact-box{
  width: 100%;
  padding: 12% 0;
  background: #EFC206;

  .tel-box{
    display: flex;
    justify-content: center;
  }
  p{
    &.tel{
      position: relative;
      padding-left: 35px;
      span{
        font-size: 2.2rem;
        color: #000;
        @include open-sans();
        font-weight: bold;
      }
      &:after{
        top:16px;
        left: 0;
        position: absolute;
        content: "";
        width: 30px;
        height: 30px;
        background: url("../images/common/pct_tel.svg") no-repeat;
        background-size: cover;
      }
    }
    &.btn{
      margin-top: 25px;
      text-align: center;
      a{
        font-size: 1rem;
        font-weight: bold;
        @include original-button();
        margin: 0 auto;
        padding: 10px 0;
        width: 80%;
      }
    }
  }
  ul{


    li{
      margin-top: 2%;
      margin-right: 18px;
      line-height: 1.0;
      font-size: 1rem;
      font-weight: bold;
      vertical-align: middle;
      align-items: center;
      display: flex;
      justify-content: center;
      img{
        width: 23px;
        height: 23px;
        margin-right: 8px;
      }
    }
  }
}

.sp-menu-open{

  transform:scaleX(1);
}
