#eat-title{
  figure{
    background: url("../images/eat/eat_main_img.jpg") no-repeat center center;
    background-size: cover;
  }
  .title-field{
    position: absolute;
    z-index: 3;

    @include mq-pc(){
      width: 287px;
      top:50px;
      left: 50%;
      margin-left: -500px;
    }
    @include mq-sp(){
      width: 140px;
      top:30px;
      left: 20px;
    }
  }
}

.damakoan{

  @include mq-pc(){
    width: 1000px;
    margin: 86px auto 0;
    display: flex;
    justify-content: space-between;
  }

  @include mq-sp(){
    width: 90%;
    margin: 15% auto 0;
  }

  img{
    width: auto;
  }

  .co-l{
    position: relative;
    @include mq-pc(){
      width: 420px;
      padding-top: 45px;
    }
    @include mq-sp(){
      width: 100%;
    }

    p{
      &.prt{
        line-height: 1;
        position: absolute;

        @include mq-pc(){
          top:95px;
          right: -10px;
          width: 82px;
          height: 82px;
        }
        @include mq-sp(){
          top:15%;
          right: -10px;
          width: 22%;
          height: 22%;
        }
      }

      &._open{
        margin-top: 1rem;
      }
    }

    h2{
      width: 314px;
      @include mq-sp(){
        width: 75%;
      }
    }
    @include eigyo();

  }
  .co-r{
    @include mq-pc(){
      width: 580px;
    }
    @include mq-sp(){
      width: 90%;
      margin-left: 10%;
      margin-top: 15%;

      img{
        width: 100%;
      }
    }
  }
}

h2{
  &.menu-h2{
    margin-top: 95px;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;

    @include mq-sp(){
        margin-top: 15%;
        font-size: 1.3rem;
    }
    span{
      position:relative;
      &:after,
      &:before{
        position: absolute;
        background: #333;
        content: "";
        width: 30px;
        height: 1px;
        top:10px;
      }

      &:before{
        left:-50px;
      }
      &:after{
        right: -50px;
      }

    }
  }
}

.eat-concept{

  @include mq-pc(){
    width: 1000px;
    margin: 0px auto 0;
    padding-left: 60px;
    display: flex;
    justify-content: space-between;
  }

  @include mq-sp(){
    width: 90%;
    margin: 0px auto 0;
  }

  figure{
    position: relative;

    @include mq-pc(){
      width: 390px;
      top:-60px;
    }
    @include mq-sp(){
      width: 70%;
      top:-30px;
    }
  }
  .text-field{
    @include mq-pc(){
      width: 490px;
      padding-top: 65px;
    }
    @include mq-sp(){
      width: 100%;
      padding-top: 6%;
    }
    h3{
      position: relative;
      padding-left: 63px;
      img{
        width: auto;
        height: 28px;
      }
      &:after{
        content: "";
        position: absolute;
        width: 135px;
        height: 1px;
        background: #333;
        top:15px;
        left: -90px;
      }
      @include mq-sp(){
        padding-left: 25px;
        img{
          height: 18px;
        }
        &:after{
          width:1px;
          height: 100px;

          top:-80px;
          left: 10px;
        }
      }
    }
    p{
      padding-top: 35px;
    }
  }
}

.menu{
  width: 1000px;
  margin: 0 auto;
  @include mq-sp(){
    width: 90%;
  }
}
.damakonabe{

  position: relative;

  @include mq-pc(){
    margin-top: 35px;
    display: flex;
    justify-content: space-between;

  }
  @include mq-sp(){
    margin-top: 8%;
  }
  &:after{
    content: "";
    position: absolute;
    background: #A89588;
    transform: skewY(-12deg);
    z-index: -1;
    @include mq-pc(){
      width: 100%;
      height: 350px;
      left: -55%;
      top:120px;
    }
    @include mq-sp(){
      width: 100%;
      height: 320px;
      right: 10%;
      top:8%;
    }
  }
  img{
    width: auto;
  }
  .img-field{
    position: relative;

    @include mq-pc(){
      margin-left: 55px;
      width: 462px;

      figure{
        width: 100%;
      }
    }
    @include mq-sp(){
      width: 100%;

      figure{
        position: relative;
        left: 15%;
        width: 100%;
      }
    }

    p{
      &.prt{
        position: absolute;
      }
      &.yuge{
        width: 154px;
        left: 0;
        top:-20px;
        @include mq-sp(){
          width: 124px;
          top:0%;
          left: 10%;
        }
      }
      &.azi{
        width: 128px;
        left: -45px;
        bottom:0px;
        @include mq-sp(){
          width: 110px;
          left: 10%;
        }
      }
    }

  }
  .text-field{
    width: 415px;
    padding-top: 55px;

    @include mq-sp(){
      width: 100%;
      padding-top: 12%;
    }
    h3{
      width: 358px;
      position: relative;

      &:after{
        content: "";
        position: absolute;
        width: 145px;
        height: 1px;
        background: #333;
        top:25px;
        left: -165px;
      }

      @include mq-sp(){
        width: 100%;
        padding-top: 12%;

        img{
          width: auto;
          height: 15vw;
        }
        &:after{
          content: "";
          width: 1px;
          height: 100px;
          top:-80px;
          left: 10px;
        }
      }
    }
    p{
      margin-top: 55px;
      @include mq-sp(){
        margin-top: 8%;
      }
    }
  }
}
.menu-list2{

  @include mq-pc(){
    width: 1000px;
    margin: 15px auto 0;
    display: flex;
    flex-wrap: wrap;

  }
  @include mq-sp(){
    width: 90%;
    margin: 3% auto 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .b{
    position: relative;
    @include mq-pc(){
      margin-top: 45px;
      width: 200px;
      margin-right: 50px;

      &:after{
        content: "";
        position: absolute;
        height: 50px;
        width: 1px;
        background: #ccc;
        right: -20px;
        top:0;
      }
    }
    @include mq-sp(){
      margin-top: 10%;
      width: 90%;
    }
  }
  .b2{
    width: 650px;
    margin-top: 45px;
    display: flex;
    justify-content: space-between;

    @include mq-sp(){
      margin-top: 10%;
      display: flex;
      justify-content: space-between;
    }
  }

  .bb{
    display: flex;
    justify-content: space-between;
  }

  h3{
    line-height: 1.0;
    font-size: 1.0rem;
    font-weight: bold;

  }
  p{
    line-height: 1.0;
    span{
      font-size: 0.9rem;
      font-weight: bold;
    }

    &.f{
      margin-top: 8px;
      text-align: right;
    }
  }

  .topping{
    display: flex;
    justify-content: space-between;
    p{
      margin-right: 12px;
    }

    @include mq-sp(){
      display: block;

      p{
        margin-bottom: 3%;
      }
    }


  }
}
.menu-list{

  margin: 0 auto;
  @include mq-pc(){
    width: 1000px;

    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  @include mq-sp(){
    width: 90%;
    margin: 0 auto;
  }
  .b{
    margin-top: 75px;
    width: 313px;
    position: relative;
    @include mq-sp(){
      margin-top: 12%;
      width: 100%;
    }

    &._caution{


      @include mq-pc(){
        display: flex;
        align-items: flex-end;
      }
      & > div{
        background: #f5f5f5;
        padding: 25px;
        @include mq-sp(){
          display: block;
          padding: 10%;
        }
      }



      p{
        &._price2{
          text-align: right;
          margin-bottom: 1rem;
          span{
            font-size: 1.2rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .b2{
    position: relative;
    margin-top: 75px;
    width: 192px;

    @include mq-sp(){
      margin-top: 12%;
      width: 100%;
    }

  }
  h3{
    margin-top: 18px;
    font-size: 1rem;
    font-weight: bold;
    span{
      font-size: 0.8rem;
    }
    @include mq-sp(){
      margin-top: 5%;
      font-size: 1.2rem;
    }
  }
  p{
    &.price{
      position: absolute;
      width: 95px;
      height: 95px;
      top:-25px;
      right: -25px;
      @include mq-sp(){
        width: 80px;
        height: 80px;
        top:-25px;
        right: -25px;
      }
    }
    &.price2{
      position: absolute;
      width: 70px;
      height: 70px;
      top:-25px;
      right: -25px;
      @include mq-sp(){
        width: 80px;
        height: 80px;
        top:-25px;
        right: -25px;
      }
    }
    &.discription{
      font-size: 0.72rem;
      margin-top: 8px;
      line-height: 1.5;
    }
  }
  dl{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
    padding-top: 35px;

    @include mq-sp(){
      border-top:1px solid #ccc;
      margin-top: 5%;
      padding-top: 5%;
    }
    dt,dd{
      width: 100%;
      font-size: 0.8rem;
    }
    dt{
      width: 50%;
    }
    dd{
      width: 50%;
      span{
        font-weight: bold;
        font-size: 1rem;
      }
    }
  }
}

.menu-photos{

  display: flex;
  flex-wrap: wrap;

  @include mq-pc(){
    margin-top:55px;
    justify-content:center;
  }
  @include mq-sp(){
    margin-top:10%;
    justify-content: space-between;
  }

  figure{

    @include mq-pc(){
      margin-top: 25px;
      width: 300px;
      margin-right: 50px;

      &:nth-child(3),
      &:nth-child(5){
        margin-right: 0px;
      }
    }
    @include mq-sp(){
      margin-top: 8%;
      width: 48%;
    }
  }
  p{
    font-size: 0.72rem;
    font-weight: bold;
    text-align: right;
  }
}
.oomori{
  background: #f5f5f5;
  padding: 8px 0;
  margin: 55px 0;

  @include mq-sp(){
    margin: 10% 0;
  }

  p{
    font-size:  0.8rem;
    text-align: center;
    span{
      font-size: 1rem;
      font-weight: bold;
    }
    &.caution{
      font-size: 0.72rem;
    }
  }

  .oomori-in{
    display: flex;


    @include mq-pc(){
      justify-content: center;
      padding: 12px 45px;
    }
    @include mq-sp(){
      flex-direction: column;
      padding: 8% 12%;
    }

    & > div{
      @include mq-pc(){
        display: flex;

        &:first-child{
          margin-right: 18px;
          padding-right: 18px;
          border-right: 1px solid #ccc;
        }
      }
      @include mq-sp(){
        &:nth-child(2){
          margin-top: 8%;
          padding-top: 8%;
          border-top: 1px solid #ccc;
        }
      }

    }
    h3,p{
      @include mq-pc(){
        line-height: 1;
      }
    }
    h3{
      font-size: 1rem;
      font-weight: bold;
      padding-right: 18px;
    }
    p{
      text-align: left;
    }
  }
}
.service{
  @include mq-pc(){
    width: 1000px;
    padding-bottom: 85px;
    margin: 75px auto 0;
    display: flex;
    justify-content: space-between;
  }
  @include mq-sp(){
    width: 90%;
    margin: 0 auto;
    padding-bottom: 15%;
  }

  .b{
    position: relative;
    width: 465px;
    padding-top: 35px;

    @include mq-sp(){
      width: 100%;
      margin: 10% auto 0;
    }
    &:after{
      content: "";
      position: absolute;
      width: 100%;
      height: 5px;
      top:0;
      left: 0;
      background: url("../images/common/back_04.png")
    }
  }
  .drink{
    @include mq-pc(){
      display: flex;
      justify-content: space-between;
    }
    figure{
      text-align: center;
      img{
        width: auto;
        height: 109px;
      }

      @include mq-sp(){
        width: 100%;
        img{
          height: 80px;
        }
      }
    }
    .text-field{

      @include mq-pc(){
        width: 350px;
      }
    }
    h3{
      img{
        width: auto;
        height: 23px
      }
      @include mq-sp(){
        text-align: center;
        margin-top: 8%;
        img{
          height: 23px
        }
      }
    }
    p{
      margin-top: 18px;
      @include mq-sp(){
        margin-top: 5%;
        text-align: center;
      }
    }
    dl{
      border-top:1px solid #ccc;
      margin-top: 18px;
      padding-top: 18px;
      display: flex;
      align-items: center;
      @include mq-sp(){
        justify-content: center;
      }
      dt,dd{
        margin-right: 8px;
        span{
          font-size: 1.1rem;
          font-weight: bold;
        }
      }
    }

  }
  .gakko{
    .img-field{
      @include mq-pc(){
        display: flex;
        position: relative;
      }
      img{
        width: auto;
      }
      figure{

        @include mq-pc(){
          margin:0 12px;
          img{
            height: 48px;
          }
        }
        @include mq-sp(){
          width: 100%;
          text-align: center;

          img{
            height: 40px;
          }
        }
      }
      h3{
        img{
          height: 66px;
        }

        @include mq-sp(){
          margin-top: 8%;
          text-align: center;
          img{
            height: 55px;
          }
        }
      }
      p{
        &.prt{
          position: absolute;
          top:0;
          right: 0;
          width: 76px;
          height: 76px;
          @include mq-sp(){
            top:20px;
            width: 65px;
            height: 65px;
          }
        }
      }
    }
  }
  p{
    &.text{
      margin-top: 28px;
    }
  }
}
.buffet{
  margin-top: 65px;
  padding: 55px 0;
  background: url("../images/eat/eat_back_01.jpg");

  @include mq-sp(){
    margin-top: 20%;
    padding: 15% 0;
  }
  .inner{

    margin: 0 auto;

    @include mq-pc(){
      width: 980px;
      display: flex;
      justify-content: space-between;
    }

    @include mq-sp(){
      width: 90%;
    }

  }
  .co-l{
    position: relative;

    @include mq-pc(){
      width: 475px;
    }
  }
  .co-r{
    @include mq-pc(){
      width: 450px;
    }
  }

  h2{

    @include mq-pc(){
      img{
        width: auto;
        height: 103px;
      }
    }

    @include mq-sp(){
      text-align: center;
      img{
        width: 100%;
      }
    }
  }
  p{
    &.prt{
      position: absolute;
      @include mq-pc(){
        bottom:-30px;
        right: 0;
        width: 104px;
        height: 104px;
      }

      @include mq-sp(){
        bottom:-10px;
        right: 0;
        width: 80px;
        height: 80px;
      }
    }
  }
  dl{

    @include mq-pc(){
      margin-top: 55px;
      display: flex;
    }
    @include mq-sp(){
      margin-top: 6%;
    }
    dt{
      margin-right: 18px;

      @include mq-sp(){
        margin-bottom: 3%;
      }
    }
    dt,dd{
      font-size: 0.78rem;
      color: #fff;
      line-height: 1.5;
    }
  }
  table{
    width: 100%;
    @include mq-sp(){
      margin-top: 12%;
    }
    td,th{
      border-bottom: 1px dotted #fff;
      font-size: 0.8rem;
      font-weight: bold;
      color: #fff;
      padding: 18px 0;
      line-height: 1.0;
      span{
        font-size: 1rem;

      }
    }
    td{
      text-align: right;
    }
  }
}
.over70{

  @include mq-pc(){
    width: 910px;
    margin: 85px auto 0;
    padding-bottom: 65px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  @include mq-sp(){
    width: 90%;
    margin: 12% auto 0;
    padding-bottom: 15%;
  }

  .title{
    position: relative;

    @include mq-pc(){
      width: 321px;
    }
    @include mq-sp(){
      padding-left: 40px;
    }

    img{
      width: auto;
      height: 99px;
      @include mq-sp(){
        height: 70px;
      }
    }
    @include mq-sp(){
      &:after{
        content: "";
        position: absolute;
        width: 1px;
        height: 110px;
        background: #333;
        top:0;
        left: 20px;
      }
    }

    @include mq-pc(){
      &:after,
      &:before{
        content: "";
        position: absolute;
        width: 120px;
        height: 1px;
        background: #ccc;
        right: -100px;

      }
      &:after{
        top:10px;
        transform: rotate(45deg);
      }
      &:before{
        bottom:10px;
        transform: rotate(-45deg);
      }
    }
  }
  .img{
    width: 475px;
    @include mq-sp(){
      img{
        width: auto;
        height: 138px
      }
    }

    @include mq-sp(){
      width: 100%;
      margin-top: 3%;
      text-align: center;

      img{
        width: 100%;
      }
    }


  }
}
