#shopping-title{
  figure{
    background: url("../images/shopping/shopping_main_img.jpg") no-repeat center center;
    background-size: cover;
  }
  .title-field{

    position: absolute;
    z-index: 3;

    @include mq-pc(){
      width: 212px;
      top:50px;
      left: 50%;
      margin-left: -450px;
    }
    @include mq-sp(){
      width: 100px;
      top:30px;
      left: 20px;
    }
  }
}

.moriyama-co{
  width: 100%;
  position: relative;
  &:after,
  &:before{
    content: "";
    position: absolute;
  }
  &:after{
    background: url("../images/common/pct_pumpkin.svg") no-repeat;
    background-size: cover;

    @include mq-pc(){
      width: 92px;
      height: 80px;
      top:60px;
      left: 50%;
      margin-left: -240px;
    }
    @include mq-sp(){
      width: 67px;
      height: 58px;
      top:40%;
      left: -10px;
    }
  }
  &:before{
    background: url("../images/common/pct_chinesecabbage.svg") no-repeat;
    background-size: cover;

    @include mq-pc(){
      width: 100px;
      height: 135px;
      top:40px;
      left: 50%;
      margin-left: 420px;

    }
    @include mq-sp(){
      width: 59px;
      height: 80px;
      top:8%;
      right: 30px;
    }
  }

  .inner{
    margin: 0 auto;

    @include mq-pc(){
      width: 1000px;
      padding: 120px 0 85px 0;
      display: flex;
      justify-content: space-between;
    }
    @include mq-sp(){
      width: 80%;
      margin: 0 auto;
      padding: 18% 0 0 0;
    }

  }
  .co-l{
    width: 400px;

    @include mq-sp(){
      width: 100%;
    }

    @include eigyo();

  }
  .co-r{
    width: 492px;
    padding-top: 0px;

    @include mq-sp(){
      width: 100%;
      margin-top: 16%;
      padding-left: 10%;
    }
    h2{
      position: relative;
      img{
        width: 345px;
        height: 35px;
      }

      &:after{
        content: "";
        position: absolute;
        width: 100px;
        height: 1px;
        background: #333;
        top:15px;
        left: -120px;
      }

      @include mq-sp(){
        img{
          width: auto;
          height: 7vw;

        }

      }
    }
    p{
      margin-top: 25px;
    }
  }


}
.item-list{
  padding-bottom:130px;

  @include mq-sp(){
    padding-bottom:15%;
  }
}
.shopping-co{

  @include mq-pc(){
    width: 900px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
  @include mq-sp(){
    width: 90%;
    margin: 16% auto 0;
    display: flex;
    flex-direction: column-reverse;
  }

  .text-field{
    width: 413px;
    padding-top: 85px;

    @include mq-sp(){
      width: 90%;
      padding-top: 13%;
      margin-left: 30px;
    }

    h3{
      position: relative;
      display: inline-block;
      @include mq-pc(){
        &:after{
          position: absolute;
          background: #333;
          content: "";
          width: 200px;
          height: 1px;
          top:20px;
          right: -220px;
          z-index: 3;
        }
        img{
          width: auto;
          height: 35px;
        }
      }

      @include mq-sp(){
        padding-left: 40px;
        img{
          width: auto;
          height: 5.5vw;
        }
        &:after{
          position: absolute;
          background: #333;
          content: "";
          width: 1px;
          height: 130px;
          top:-100px;
          left: 15px;
          z-index: 3;
        }
      }
    }
    p{
      margin-top: 45px;
      @include mq-sp(){
        margin-top: 8%;
      }
    }
  }
  .img-field{
    width: 344px;
    position: relative;

    @include mq-sp(){
      width: 250px;
      margin: 0 auto;
    }
    figure{
      position: relative;
      &:after{
        content: "";
        width: 100%;
        height: 100%;
        left: 20px;
        top:20px;
        background: url("../images/common/back_03.svg");
        background-size: 32px 32px;
        border-radius: 50%;
        z-index: -1;
        position: absolute;
      }
    }
    p{
      @include open-sans();
      position: absolute;
      z-index: 2;
      width: 100%;
      font-weight: bold;
      font-style: italic;
      text-align: right;
      bottom:10px;
      color: #333;
    }
  }
}
@include mq-pc(){
  .reserve{
    flex-direction: row-reverse;
    h3{
      &:after{
        position: absolute;
        background: #333;
        content: "";
        width: 200px;
        height: 1px;
        top:20px;
        left: -220px;
        z-index: 3;
      }
    }
  }
}

@include mq-pc(){


  .veg-co{
    .text-field{
      h3{
        img{
          width: 422px;
          height: 35px;
        }
      }
    }
  }
  .process-co{
    .text-field{
      h3{
        img{
          width: 399px;
          height: 35px;
        }
      }
    }
  }
  .souvenir-co{
    .text-field{
      h3{
        img{
          width: 228px;
          height: 35px;
        }
        &:after{
          width: 400px;
          right: -420px;
        }
      }
    }

  }

  .flower-co{
    .text-field{
      h3{
        img{
          width: 114px;
          height: 35px;
        }
      }
    }
  }
}
