
body{
	overflow-x: hidden;
	-webkit-text-size-adjust: 100%;
	position: relative;
}
html{
	@include mq-pc{
		font-size:16px;
	};
	@include mq-sp{
		font-size:3.8vw;
	};
}

.pc-non{
	@include mq-pc{
		display: none;
	}
}
.sp-non{
	@include mq-sp{
		display: none;
	}
}


div{
	box-sizing: border-box;
}

p,li,dt,dd,th,td{
	line-height: 2;
	letter-spacing: 1.5px;
	color: #363334;
	@include mq-pc{
		font-size: 0.82rem;
	};
	@include mq-sp{
		font-size: 0.8rem;
	};
}
img{
	width: 100%;
}

a{
	color: #333;
}

input[type="text"],
input[type="email"],
input[type="tel"],
textarea{
	width: 100%;
	font-size:0.9rem;
	padding: 12px;
	box-shadow: 0 0 3px #eee inset;
	border:none;

	&::placeholder{
		color: #BABABA;
	}
	&:input-placeholder{
		color: #BABABA;
	}
}


input[type="text"],
input[type="email"],
input[type="tel"]{
	border-bottom:2px solid #333;
}

textarea{
	height: 120px;
	border:2px solid #333;
}

input[type='button'],
input[type='submit']{
	font-size: 1rem;
	background: none;
	color: #333;
	border:none;
	font-weight: bold;
	@include mq-sp(){
		width: 100%;
		height: 50px;
	}

}
