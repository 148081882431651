#barba-wrapper{
  width: 100%;
}

.barba-container{
  transition: 0.5s;
  width: 100%;
  left: 0;
}

.body-start{
  overflow: hidden;
}

.page-change{
  opacity: 0;
}

.loading{
  top:0;
  left: 0;
  z-index: 999;
  position: absolute;
  width: 100%;
  height: 100%;

  transform: scaleX(0);
  transform-origin: 0 100%;
  background: rgba(0,0,0,0.58);

}
.on-load{
transition: 0.5s;
  transform: scaleX(1);
}
.on-load-start{
  opacity: 0;
}

.door-top,
.door-bottom{
  position: absolute;
  width: 100vw;
  height: 50vh;
  background: #FFB817;
  transition: 0.5s;
  z-index: 997;
}
.door-top{
  top:0%;
  transform: scaleX(0) rotate(-45deg);
  transform-origin: 0% 0;
}
.door-bottom{
  transform: scaleX(0) rotate(-45deg);
  top:50vh;
  transform-origin: 100% 50vh;
}
.door-close{
  transform: scaleX(1) rotate(0deg);
}
.door-close2{
  transform: scaleX(1) rotate(0deg);
  transform-origin: 100% 50vh;
}

.load-line{
  width: 150%;
  position: fixed;
  left: -150%;
  z-index: 999;
  top:50vh;
  &:after{
    position: absolute;
    content: "";
    width: 100%;
    height: 1px;
    background: #666;
  }
  &:before{
    position: absolute;
    content: "";
    background: url("../images/common/ico_car.svg") no-repeat;
    background-size: cover;
    width: 50px;
    height: 50px;
    top:-36px;
    right:0;
  }
}
.load-line-on{
  @include animation(lineAnime 1s ease 0.5s);
}
.load-line-off{
  @include animation(lineAnimeOff 0.5s ease 0.5s);
}
