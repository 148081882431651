#cafe-title{
  figure{
    background: url("../images/cafe/cafe_main_img.jpg") no-repeat center center;
    background-size: cover;
  }
  .title-field{
    position: absolute;
    z-index: 3;

    @include mq-pc(){
      width: 223px;
      top:50px;
      left: 50%;
      margin-left: -500px;
    }
    @include mq-sp(){
      width: 112px;
      top:30px;
      left: 20px;
    }
  }
}

.hirogano{
  @include mq-pc(){
    width: 1000px;
    margin: 86px auto 0;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  @include mq-sp(){
    width: 90%;
    margin: 12% auto 0;
  }

  .co-l{
    width: 450px;
    position: relative;
    z-index: 2;
    padding-top: 45px;

    @include mq-sp(){
      width: 100%;
      padding-top: 8%;
    }
  }
  .co-r{
    width: 580px;
    position: absolute;
    top:0;
    right: 0;

    @include mq-sp(){
      width: 90%;
      position: static;
      padding-top: 8%;
    }
  }

  h2{
    img{
      width: auto;
      height: 90px;
    }
    @include mq-sp(){
      img{
        height: 18vw
      }
    }
  }
  p{
    &.prt{
      width: 82px;
      position: absolute;
      right: 0;
      top:55px;

      @include mq-sp(){
        width: 18vw;
        right: 0;
        top:10%;
      }
    }
  }
  @include eigyo();

}
.hirogano2{
  @include mq-pc(){
    width: 1000px;
    margin: 86px auto 0;
    display: flex;
    justify-content: space-between;
  }
  @include mq-sp(){
    width: 100%;
    margin: 0 auto;

  }

  figure{
    width: 406px;
    margin-left:75px;
    position: relative;
    top:-30px;

    @include mq-sp(){
      width: 50%;
      margin-left:50%;
      top:-10vw;
    }
  }
  .co-r{
    padding-top: 105px;
    width: 425px;

    @include mq-sp(){
      width: 80%;
      padding-top: 0%;
      margin-left: 12%;
    }
  }
  h3{
    position: relative;
    img{
      width: auto;
      height: 63px;

      @include mq-sp(){
        height: 14vw;
      }
    }


    &:after{
      content: "";
      position: absolute;
      @include mq-pc(){
        width: 150px;
        height: 1px;
        background: #333;
        top:10px;
        left: -160px;
      }
      @include mq-sp(){
        width: 1px;
        height: 300px;
        background: #333;
        top:-250px;
        left: -12px;
      }

    }
  }
  p{
    margin-top: 35px;
    @include mq-sp(){
      margin-top: 12%;
    }
  }
}

.cafe-menu{
  position: relative;
  padding-bottom: 85px;

  @include mq-sp(){
    padding-bottom: 20px;
  }
  p{
    &.back{
      position: absolute;
      width: 443px;
      bottom:-200px;
      right: 0;
      z-index: -1;

      @include mq-sp(){
        width: 50%;
        bottom:-50px;
      }
    }
  }
  .cafe-damako{
    @include mq-pc(){
      width: 1000px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
    }
    @include mq-sp(){
      width: 90%;
      margin: 0 auto;
      margin-top: 12%;

    }
    .co-l{
      width: 510px;
      position: relative;

      @include mq-sp(){
        width: 120%;
        left: 10%;
      }
    }
    .co-r{
      width: 415px;
      padding-top: 85px;

      @include mq-sp(){
        width: 90%;
        padding-top: 10%;
        margin-left: 10%;
      }
    }
  }

  h2{
    position: relative;
    img{
      width: auto;
      height: 80px;

      @include mq-sp(){
        height: 15vw;
      }
    }
    &:after{
      content: "";
      position: absolute;
      width: 135px;
      height: 1px;
      background: #333;
      top:15px;
      left: -150px;
    }
  }
  p{
    &.prt{
      position:absolute;
      font-size: 5rem;
      @include open-sans();
      font-weight: bold;
      font-style: italic;
      left: 0;
      bottom: 0;
      color: #333;
    }
    &.text{
      margin-top: 35px;
    }
  }
  .cafe-menu-list{
    width: 1000px;
    margin: 0 auto;

    @include mq-sp(){
      width: 90%;
    }
    ul{
      @include mq-pc(){
        margin-left: 55px;
        margin-top: 35px;
        display: flex;
        flex-wrap: wrap;
      }
      @include mq-sp(){
        margin-left: 10%;
        margin-top: 8%;
      }
      li{
        line-height: 1.0;
        margin-top: 28px;
        display: flex;
        align-items: center;
        font-weight: bold;
        font-size: 0.9rem;
        justify-content: space-between;


        @include mq-pc(){
          width: 30%;
          margin-right: 5%;
          &:nth-child(3n){
            margin-right: 0;
          }
        }
        @include mq-sp(){
          width: 100%;
        }

        span{
          position: relative;
          &:after{
            content: "";
            position: absolute;
            width: 30px;
            height: 1px;
            background: #333;
            top:15px;
            left: -40px;

            @include mq-sp(){
              top:8px;
            }
          }
        }
        i{
          font-size: 1.5rem;
          @include open-sans();
        }
      }
    }
    .drink-bar{
      border: 1px solid #333;

      @include mq-pc(){
        padding: 15px 25px;
        margin-left: 55px;
        margin-top: 35px;
        display: flex;
        width: 70%;
      }

      @include mq-sp(){
        padding: 8% 8%;
        margin-top: 8%;
        width: 90%;
        box-sizing: border-box;
        margin: 8% 0 0 10%;

        p{
          margin-top: 5%;
        }
      }

      @include mq-pc(){
        ul{
          width: 250px;
          margin: 0;
          margin-right: 35px;
          li{
            width: 100%;
            margin: 0;
          }
        }
      }
      @include mq-sp(){
        ul{
          width: 100%;
          margin: 0;
          li{
            width: 100%;
            margin: 0;
          }
        }
      }

    }

  }

  .cafe-drink{

    width: 1000px;
    margin: 0 auto;

    @include mq-sp(){
        width: 90%;
    }
    .inner{
      margin-left: 55px;
      margin-top: 65px;
      display: flex;
      align-items: center;

      @include mq-sp(){
        margin-left: 10%;
        margin-top: 18%;
      }
    }
    figure{
      margin-right: 18px;
      img{
        width: auto;
        height: 80px
      }
      @include mq-sp(){
        margin-right: 18px;
        img{
          width: auto;
          height: 15vw;
        }
      }
    }
    h3{
      position: relative;
      line-height: 1.0;
      img{
        width: auto;
        height: 31px;

        @include mq-sp(){
          height: 8vw;
        }
      }
      span{
        @include open-sans();
        font-size: 0.78rem;
        font-weight: bold;
      }

      &:after{
        content: "";
        position: absolute;
        width: 3000px;
        height: 1px;
        background: #333;
        top:50px;
        right: -2960px;

        @include mq-sp(){
          top:98%;
        }
      }
    }
  }
}
