$type: common phone screen;
$sp:  "only screen and (max-width:750px)";
$tb: "only screen and (min-width:641px) and (max-width:959px)";
$pc:  "only screen and (min-width:751px)";

@mixin mq-pc {
  @media screen and (min-width:751px) {
    @content;
  }
}
@mixin mq-sp {
  @media screen and (min-width:0px) and (max-width: 750px) {
    @content;
  }
}

@mixin eigyo(){
  dl{
    display: flex;
    align-items: baseline;
    margin-top: 25px;
    @include mq-sp(){
      margin-top: 1%;
    }

    @include mq-sp(){
      margin-top: 5%;
      flex-wrap: wrap;
    }
    dt,dd{
      font-size: 0.72rem;
      margin-right: 8px;
      line-height: 1.5;
    }
    dt{
      color: #fff;
      line-height: 1;
      padding: 3px 8px;
      font-size: 0.68rem;
      background: #159A51;
      text-align: center;
      &.touki{
        background: #205C8D;
      }
      @include mq-sp(){
        width: 20%;
        margin-top: 3%;
        padding: 1.5% 5%;
      }
    }
    dd{
      @include mq-sp(){
        width: 70%;
        margin-top: 3%;
      }
    }
  }

  p{
    &.caution{
      font-size: 0.72rem;
      margin-top: 25px;
    }
  }
}

@mixin imageAnimation(
$bgc:#51C5BE
){

  width: 100%;
  overflow: hidden;
  position: relative;
  &:after{
    content: "";
    width: 100%;
    height: 100%;
    background: $bgc;
    position: absolute;
    top:0;
    left: -100%;
  }
  img{
    width: 100%;
    left: -100%;
    position: relative;
  }
  &.figureAnimation{
    &:after{
      @include animation(amine1 1.0s ease 0s);
    }
    img{
      @include animation(amine2 1.0s ease 0s);
    }
  }
}

@mixin keyframes($animation-name: animaton) {

  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @-moz-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}

@mixin animation($animation-name) {
  animation: $animation-name;
  -moz-animation: $animation-name;
  animation: $animation-name;
  animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

@mixin clearfix(){
  &:after   {
    content: "";
    display: block;
    visibility: hidden;
    height: 0.1px;
    font-size: 0.1em;
    line-height: 0;
    clear: both;
    zoom: 1;
  }
}
@mixin original-button(){
  padding: 15px 105px;
  display: inline-block;
  text-decoration: none;
  border:2px solid #000000;
  position: relative;
  overflow: hidden;
  span{
    position: relative;
    z-index: 2;
  }
  &:after{
    position: absolute;
    transition: 0.7s;
    content: "";
    top:-500px;
    left: -500px;
    z-index: 1;
    width: 1000px;
    height:1000px;
    background: #FFCC00;
    transform: scale(0);
    transform-origin: 50%;
    border-radius: 50%;
  }
  &:hover{
    &:after{
      transform: scale(1);
    }
  }
}
@mixin drow-three-line(
$pos:10px,
$width:100%
){
  i{
    display: block;
    width: $width;
    position: absolute;
    height: 2px;
    background: #333;
    transition: 0.3s;
    opacity: 1;
    left: -10px;
    top:0;
  }
  &:after,
  &:before{
    content: "";
    position: absolute;
    transform: rotate(0deg);
    top:-$pos;
    left: -10px;
    width: $width;
    height: 2px;
    background: #333;
    transition: 0.3s;
  }
  &:before{
    top:$pos;
  }

}
@mixin draw-allow(
$w:8px,
$h:15px,
$hypotenuse:10.97px,
$deg:43.15deg,
$lineWeight:1px,
$c:#333
){
  position:absolute;
  width: $w;
  height: $h;
  &:after,
  &:before
  {
    position: absolute;
    content: "";
    width:$hypotenuse;
    height: $lineWeight;
    left: 0;
    top:calc(#{$h/2});
    background: $c;
    transition: 0.3s;
    transform-origin: 0% 0%;
    z-index: 9;
  }
  &:after{
    transform: rotate($deg);
  }
  &:before{
    transform: rotate(-$deg);
  }
}

@mixin custom-btn(
$c1:#323232,
$c2:#747474,
$c3:#979797,
$c4:#F0F0F0
){
  transition: 0.3s;
  color: $c4;
  text-decoration: none;
  background: $c1;
  padding: 8px 18px;
  display: flex;
  box-sizing: border-box;
  justify-content: space-between;
  position: relative;
  align-items: center;
  i{
    position: relative;
    width: 25px;
    transition: 0.3s;
    &:after,
    &:before{
      transition: 0.3s;
      position: absolute;
      content: "";
      background: $c3;
    }
    &:after{
      width: 20px;
      height: 1px;
      right: 0;
      top:0

    }
    &:before{
      right: 0;
      top:-5px;
      width: 12px;
      height: 1px;
      transform: rotate(45deg);
    }
  }
  &:hover{
    background: $c2;
    i{
      right:6px;
      &:after{
        right: 0;
        top:-5px;
        height: 1px;
        transform: rotate(0deg);
      }
      &:before{
        height: 1px;
        right: 0;
        top:0;
        transform: rotate(0deg);
      }
    }
  }
}

@mixin custom-selecter(){
  overflow: hidden;
  display: inline-block;
  *display: inline;
  *zoom: 1;
  position: relative;
  border-radius: 0.2em;
  border: 1px solid #ccc;
  box-shadow: 0 1px 0 #AAA;
  background-color: white;


  select {
    -moz-appearance: none;
    -webkit-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
    appearance: none;
    border-radius: 0;
    border: 0;
    margin: 0;
    padding: 0;
    background: none transparent;
    vertical-align: middle;
    font-size: inherit;
    color: inherit;
    box-sizing: content-box;
  }

  & > select{
    width: 100%;
    padding: 0.3em 20em 0.3em 0.6em;

  }

  &:after{
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1.8em;
    display: block;
    content: "";
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAMAAAAoLQ9TAAAAGFBMVEUAAAAZJCYWISIYIyQYIiUWIiIYIyQYIiXh0rToAAAACHRSTlMA/hTRqiinvwFkb0sAAABCSURBVHjajchBDsAgDAPBOJDw/x+XVN0icQGfPGs3i+2GEqeiLCXWLENzvdzrDfP2ls/NjfKbgimYglfBq2Dm+LwHguMA235EdKYAAAAASUVORK5CYII=") center no-repeat;
    pointer-events: none;

  }
}
@mixin custom-radio(){

  input[type="radio"] {
    display: none;
  }
  label {
    position: relative;
    display: inline-block;
    padding: 15px 30px 15px 30px;
    cursor: pointer;
    border: 1px solid #ccc;
  }
  label::before,
  label::after {
    position: absolute;
    left: 10px;
    content: '';
    top: 50%;
    border-radius: 100%;
    -webkit-transition: all .2s;
    transition: all .2s
  }
  label::before {
    left: 10px;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    background: #f3f3f3;
    border: 1px solid #ccc;
  }
  label:hover::before {
    background: #fff;
  }
  label::after {
    opacity: 0;
    left: 12px;
    width: 12px;
    height: 12px;
    margin-top: -6px;
    background: #333;
    -webkit-transform: scale(2);
    transform: scale(2);
  }
  input[type="radio"]:checked + label{
    background: #f4f4f4;
  }
  input[type="radio"]:checked + label::before {
    background: #fff;
    border: 1px solid #333;
  }
  input[type="radio"]:checked + label::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }

}
@mixin costom-checkbox(){
  input[type="checkbox"] {
    display: none;
  }
  label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    -webkit-transition: all .2s;
    transition: all .2s;
    padding: 8px 30px 8px 30px;
    border: 1px solid #ccc;
    @include border-radius(3px);

  }
  label::before,
  label::after {
    position: absolute;
    content: '';
    -webkit-transition: all .2s;
    transition: all .2s;
  }
  label::before {
    top: 50%;
    left: 8px;
    width: 14px;
    height: 14px;
    margin-top: -8px;
    background: #f4f4f4;
    border: 1px solid #ccc;
    border-radius: 3px;
  }
  label::after {
    opacity: 0;
    top: 45%;
    left: 8px;
    width: 12px;
    height: 6px;
    margin-top: -4px;
    border-left: 3px solid #3498db;
    border-bottom: 3px solid #3498db;
    -webkit-transform: rotate(-45deg) scale(.5);
    transform: rotate(-45deg) scale(.5);
  }
  label:hover::before {
    background: #fff;
  }

  input[type="checkbox"]:checked + label{
    background: #EFF8FD;
  }
  input[type="checkbox"]:checked + label::before {
    background: #fff;
    border: 1px solid #3498db;
  }
  input[type="checkbox"]:checked + label::after {
    opacity: 1;
    -webkit-transform: rotate(-45deg) scale(1);
    transform: rotate(-45deg) scale(1);
  }
}
