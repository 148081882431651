
.body-load-start{
  overflow: hidden;
}

.first-loading{
  width: 100%;
  height: 100vh;
  z-index: 9999;
  opacity: 1;
  top:0;
  left: 0;
  align-items: center;
  justify-content: center;
  position:fixed;

  .loading-inner{
    text-align: center;
    width: 100%;
    height: 100vh;
    top:0;
    left: 0;
    z-index: 101;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: fixed;
    transform-origin: 50%;
  }

  &:before,
  &:after{
    content: "";
    position:fixed;
    width: 3000px;
    height: 3000px;
    left: 50%;
    margin-left: -1500px;
    border-radius: 50%;
    top:calc(-100vh/2);
    transform-origin: 50% ;
  }

  &:before{
    background: #FFB817;
    z-index: 10;
    transform: scale(0);
  }

  &:after{
    background: #fff;
    z-index: 9;
  }
  .loading-field{
    position: relative;
    z-index: 11;
    transform: scale(0);
  }
  p{
    font-size: 0.8rem;
    font-weight: bold;
    @include open-sans();
    span{
      position: relative;
    }
  }
  img{
    width: 70px;
    height: 70px;
  }
}

.body-load-start{
  .loading-field{
    @include animation(loadingAnimation2 0.5s ease-in-out 0.5s);
  }
  .first-loading{
    &:before{
      @include animation(loadingAnimation 0.8s ease-in-out 0s);
    }
  }
}

.loadComp{
  @include animation(loadingComp 0.5s ease-in-out 0.2s);
  p{
    @include animation(textComp 0.5s ease-in-out 0s);
  }
  .logo{
    @include animation(logoComp 0.5s ease-in-out 0s);
  }
}

.first-load-start{
  opacity: 0;
}
