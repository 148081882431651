.contact-field{
  width: 800px;
  margin: 55px auto 0;
  dl{
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
    dt,dd{
      line-height: 1;
      margin-top: 35px;
    }
    dt{
      padding-top: 12px;
      width: 25%;
      font-weight: bold;
      position: relative;
      span{
        position:absolute;
        right: 0;
        background: #333;
        color:#fff;
        padding: 5px 8px;
        font-weight: normal;
        font-size: 0.72rem;
        display: inline-block;
      }
    }
    dd{
      width: 70%;
    }
    p{
      color: #777;
      &:first-child{
        margin: 0;
      }
    }
  }
}

.sendBtnList{
  padding-top: 35px;
  text-align: center;
}
.custom-radio{
  @include custom-radio();
}
.custom-select{
  width: 100%;
  @include custom-selecter();
}
