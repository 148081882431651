#contact-title{
  figure{
    background: url("../images/contact/contact_main_back.jpg") no-repeat center center;
    background-size: cover;
  }
  .title-field{
    position: absolute;
    z-index: 3;

    @include mq-pc(){
      width: 522px;
      top:50px;
      left: 50%;
      margin-left: -522px;
    }
    @include mq-sp(){
      width: 250px;
      top:30px;
      left: 20px;
    }
  }
}

.contact-info{
  margin-top: 85px;
  @include mq-sp(){
    margin-top: 15%;
  }
}

.contact-form{

  background: url("../images/common/footer_line_01.svg") repeat-x;
  background-position: top center;
  background-size: 1500px 11px;

  margin-top: 80px;
  padding-bottom: 85px;

  @include mq-sp(){
    margin-top: 15%;
    padding-bottom: 8%;
  }

  .title-field{
    padding-top: 85px;
    text-align: center;
    h2{
      letter-spacing: 2px;
      font-weight: bold;
      display: inline-block;
      position: relative;
      font-size: 1.2rem;
      &:after{
        position: absolute;
        content: "";
        width: 100%;
        height: 14px;
        background: #FFCC00;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
    }
  }
}

.form-field{
  width: 800px;
  margin: 0 auto;

  @include mq-sp(){
    width: 90%;
  }

  dl{
    @include mq-pc(){
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 35px;

    }
    @include mq-sp(){
      margin-top: 10%;
    }
    dt,dd{
      @include mq-pc(){
        margin-top: 45px;
      }
    }
    dt{
      padding-top: 16px;
      line-height: 1.0;
      font-size: .8rem;
      font-weight: bold;
      width: 24%;
      position: relative;
      padding-left: 60px;

      @include mq-sp(){
        width: 100%;
        font-size: 0.9rem;
        padding-top: 0px;
        margin-top: 18%;
      }
      &:after {
        position: absolute;
        left: 0;
        top: 12px;
        display: inline-block;
        background: #DD0E0E;
        color: #fff;
        font-size: .68rem;
        padding: 3px 8px;
        content: "必須";

        @include mq-sp() {
          left: 0;
          top: -3px;
        }
      }
    }
    dd{
      width: 72%;
      @include mq-sp(){
        width: 100%;
        margin-top: 8%;
      }
    }
  }
  .err{
    
    background: #DD0E0E;
    position: relative;
    padding: 12px 18px;
    &:before{
      content: '';
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      left: 20px;
      bottom: -8px;
      border-top: 8px solid #DD0E0E;
      border-right: 8px solid transparent;
      border-left: 8px solid transparent;
    }
    p{
      margin-top:8px;
      line-height: 1.0;
      font-size: 0.72rem;
      color: #fff;
      &:first-child{
        margin-top:0px;
      }
    }
  }

  .send-btn{

    display: flex;
    justify-content: center;
    @include mq-pc(){
      margin-top:65px;
      align-items: center;
    }

    @include mq-sp(){
      margin-top:10%;
      flex-direction: column-reverse;
    }
  }
  .back-btn{
    width: 200px;
    background: #BCBCBC;
    margin-right: 18px;
    input[type="button"]{
      width: 100%;
      height: 45px;
      font-size: .8rem;
    }
    @include mq-sp(){
      margin: 8% auto 0;
    }
  }
  .confirmation{
    background: #FFCC00;
    width: 320px;
    position: relative;
    i{
      top:25px;
      right: 20px;
      position: absolute;
      @include draw-allow;
      transform: scale(-1);
    }

    input[type="button"],
    input[type="submit"]{
      width: 100%;
      height: 60px;
    }
  }
}
