
#privacy{
  padding: 105px 0;
  .inner{
    width: 900px;
    margin: 0 auto;
  }
  @include mq-sp(){
    padding: 18% 0;
    .inner{
      width: 90%;
      margin: 0 auto;
    }
  }
  h1{
    span{
      font-size: 3rem;
      @include open-sans();
      font-weight: bold;
    }
  }
  h2{
    margin-top: 55px;
    font-weight: bold;
    @include mq-sp(){
      margin-top: 12%;
    }

  }
  p{
    margin-top: 18px;
  }
  address{
    border:2px solid #333;
    margin-top: 25px;
    font-size: 0.9rem;
    padding: 25px;
    line-height: 2;
  }
}
