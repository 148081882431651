@charset "utf-8";

body{-webkit-text-size-adjust: 100%;}
body,div,dl,dt,dd,ul,ol,li,h1,h2,h3,h4,h5,h6,
pre,code,form,fieldset,legend,input,textarea,p,blockquote,th,td,figure{
    margin:0;padding:0;
		box-sizing: border-box;
}
img{
  line-height: 0;
}
figure{
	line-height: 1;
}
table{
    border-collapse:collapse;border-spacing:0;
}
fieldset,img{ border:0; }
address,caption,cite,code,dfn,em,strong,th,var{
    font-style:normal;
}
li { list-style:none; } caption,th{ text-align:left; }
h1,h2,h3,h4,h5,h6{
    font-size:100%;font-weight:normal;
}
q:before,q:after{ content:''; }
abbr,acronym{ border:0;font-variant:normal; }
sup{ vertical-align:text-top; }
sub{ vertical-align:text-bottom; }
input,textarea,select{ font-family:inherit;font-size:inherit;font-weight:inherit; }
input,textarea,select{ *font-size:100%; }
legend{ color:#000; }
