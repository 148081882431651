/*
@include animation(animationSample 1.2s ease 0.15s);
*/


.anime-default{
  @include animation(amine4 1s ease 0.4s);
}
.anime-default-css{
  position: relative;
  opacity: 0;
}

.text-animation-slide{
  span{
    @include animation(anime2 0.3s ease 0.7s);
  }
  &:after{
    @include animation(anime1 0.5s ease 0.7s);
  }
  &:before{
    @include animation(anime1 0.5s ease 0.9s);
  }
}
.text-animation-slide-default{
  span,
  &:after,
  &:before{
    left: -100%;
  }
}
@include keyframes(anime1){
  0% {
    left: -100%;
  }
  50% {
    left: 0%;
  }
  100% {
    left: 100%;
  }
}
@include keyframes(anime2){
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}

@include keyframes(lineAnime){
  0% {
    left: -150%;
  }

  100% {
    left: 100%;
  }
}
.loadingTextAnimationSet{
  img{
    @include animation(carAnimation 1s ease 0s);
    animation-iteration-count: infinite;
  }
  span{
    @for $cnt from 1 through 7{
      &:nth-child(#{$cnt}){
        @include animation(textAnimation 0.6s ease 0.2+$cnt *0.1s);
        animation-iteration-count: infinite;
      }
    }
  }
}

@include keyframes(loadingAnimation){
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@include keyframes(loadingAnimation2){
  0% {
    opacity: 0;
    transform: scale(0);
  }
  10%{
    opacity: 1;
  }
  100% {
    transform: scale(1);
  }
}
@include keyframes(loadingComp){
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@include keyframes(textAnimation){
  0% {
    top: 0px;
  }
  50% {
    top: -5px;
  }
  100% {
    top: 0px;
  }
}
@include keyframes(carAnimation){
  0% {
    transform: scaleX(1);
  }
  50% {
    transform: scaleX(-1);
  }
  100% {
    transform: scaleX(1);
  }
}
@include keyframes(logoComp){
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(2);
  }
  100% {
    transform: scale(1);
  }
}
@include keyframes(textComp){
  0% {
    opacity: 1;
    transform:translateY(0);
  }

  100% {
    opacity: 0;
    transform:translateY(-10);
  }
}


@include keyframes(anime2){
  0% {
    left: -100vw;
  }
  100% {
    left: 0vw;
  }
}
